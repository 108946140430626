.rentContainer{
    background-color: #e6e6e6;
    padding: 5% 0;
}

.sectionHeader{
    font-family: "Apfel Grotezk";
    font-weight: 400;
    margin-bottom: 3.8rem;
    color: #100f10;
    padding-left: 10px;
}

.step{
    font-family: "Space Grotesk";
    font-weight: 400;
    color: #100f10;
}
.stepTitle{
    font-family: "Apfel Grotezk";
    font-weight: 400;
    padding-right: 47%;
    margin-bottom: 2rem;
    line-height: 1.3;
    color: #100f10;
}
.stepContent{
    font-family: "Circular";
    font-weight: 400;
    margin: 0;
    padding-right: 20%;
    color: rgba(16, 15, 16, 0.8)
}

@media screen and (max-width: 991px) {
    .rentContainer{
        background-color: #e6e6e6;
        padding: 40px 24px;
    }
    .stepTitle{
        padding-right: 0%;
    }
    .stepContent{
        padding-right: 0;
    }
	.stepsContainer > div {
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 767px) {
	.stepsContainer > div {
        margin-bottom: 50px;
        text-align: left;
    }

    .stepTitle {
        padding: 0;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .sectionHeader {
        text-align: center;
    }

	.stepsContainer > div {
        text-align: center;
    }
}
