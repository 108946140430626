.bikes {
	position: relative;
	width: 100%;
	background-color: white;
	padding: 6% 0;
}

.sectionHeader {
	font-family: "Apfel Grotezk";
	font-weight: 400;
	margin-bottom: 1rem;
	text-align: center;
	color: #100f10;
}

.bikeContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bikesButton {
	font-family: "SnvD";
	font-weight: 400;
	margin: 3rem auto 0;
	display: table;
	text-transform: uppercase;
	font-size: 20px;
	background-color: #100f10;
}

.bikesButton:hover {
	background-color: #100f10;
	color: white;
}


.bikesTable {
	width: 73%;
	float: right;
	font-family: "Space Grotesk";
	font-weight: 500;
	color: #100f10;
}

.bikesTable tr:first-child {
	border-top: 1px solid #100f10;
}

.bikesTable tr {
	border-bottom: 1px solid #100f10;
}
.bikesTable tr td{
	padding: 12px 0;
	font-size: 20px;
}
.bikesTable tr td:nth-child(2) {
	width: 31%;
}

@media screen and (max-width: 1400px) {

	.bikesTable {
		width: 90%;
	}

	.bikesTable tr td {
		font-size: 18px;
	}

	.bikeContainer img{
		margin-top: 2.5rem !important;
	}

}

@media screen and (max-width: 1200px) {

	.bikeContainer img{
		margin-top: 5rem !important;
	}
	.bikesButton {
		margin: 5rem auto 0;
	}
}

@media screen and (max-width: 991px) {

	.bikes {
		padding: 40px 24px;
	}

	.sectionHeader {
		margin-bottom: 50px;
		padding: 0px 15%;
	}

	.bikesTable tr td {
		padding: 8px 0;
		font-size: 16px;
	}

	.bikesTable tr td:nth-child(2) {
		width: 40%;
	}

	.bikesTable {
		width: 100%;
		float: unset;
	}

	.bikeContainer img{
		padding: 45px 0;
		margin-top: 0.5rem !important;
	}

	.bikesButton {
		margin: 3rem auto 0;
	}
}

@media screen and (max-width: 767px) {
	.bikeContainer img{
		padding: 0;
	}

	.bikesTable {
		margin-top: 30px;
	}
}

@media screen and (max-width: 575px) {
	.bikesTable tr td {
		font-size: 15px;
	}

	.bikesButton {
		font-size: 15px;
	}
}
