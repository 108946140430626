.wipContainer {
	width: 100vw;
	height: 100vh;
	background-color: #100f10;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.wipMessage {
	word-spacing: -4;
}
