.contactContainer{
    padding: 5.4% 0;
}

.contactContainer p{
    font-weight: 400;
}

.sectionHeader{
    font-family: "Apfel Grotezk";
    font-weight: 400;
    margin-bottom: 4rem;
    border-bottom: 2px solid #100f10;
    color: #100f10;
}

.info, 
.schedule li p{
    font-family: "Circular";
    font-weight: 400;
    color: #100f10;
}

.info{
    padding-right: 5.4%;
}

.schedule {
    margin-top: 0.5rem;
    padding-left: 10%;
}

.schedule li p {
    margin-bottom: 2rem;
}

.formInput {
    font-family: "Space Grotesk";
    font-weight: 300;
    font-size: 1.25rem;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #100f10;
}
.form textarea{
    font-family: "Space Grotesk";
    font-weight: 300;
    font-size: 1.25rem;
    border-radius: 0;
    border: 1px solid #100f10;
}

.formInput::placeholder,
.form textarea::placeholder{
    font-family: "Space Grotesk";
    font-weight: 300;
    font-size: 1.25rem;
    opacity: 0.7;
}

.form button{
    font-family: 'SnvD';
    font-size: 1.25rem;
    font-weight: 400;
	text-transform: uppercase;
    letter-spacing: 0.5px;
    background-color: #100f10;
}

.form button:hover{
	background-color: #100f10;
	color: white;
}

.formCheck {
    margin-top: 24px;
    margin-bottom: 24px;
}

.formCheck > input {
    width: 24px;
    height: 24px;
}

/* .formCheck > input:checked[type=checkbox] {
    background-image: url();
} */

.formCheck > input {
    border-radius: 0 !important;
    border-color:  #100f10 !important;
}

.formCheck > input:focus {
    box-shadow: none !important;
}

.formCheck > input:checked {
    background-color: #009900 !important;
    border-color:  #100f10 !important;
}

.formCheck > label {
    margin-left: 10px;
    color: inherit !important;
    font-family: "Circular";
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}

.successMessage {
    font-family: "Circular";
    color: #100f10;
    background-color: #FFFF31;
    padding: 8px;
}

.feedbackText {
    color: #be2007;
    margin: 0;
}

.checkLabel {
    color: inherit;
}

@media screen and (max-width: 991px) {
    .contactContainer{
        padding: 40px 24px;
    }

	.rowContainer > div {
        margin-bottom: 25px;
    }

    .info {
        padding: 0;
    }

    .schedule{
        padding: 0;
    }

    .form button{
        display: table;
    }
}

@media screen and (max-width: 575px) {
    .schedule li p {
        font-size: 18px;
        margin-bottom: 1rem;
    }

    .info {
        font-size: 18px;
    }

    .formInput {
        font-size: 1.125rem;
    }

    .form button{
        font-size: 15px;
    }
}
