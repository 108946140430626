.smartContainer{
    padding-top: 8%;
}

.sectionHeader{
    font-family: "Apfel Grotezk";
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
    color: #100f10;
}

.sectionContent{
    text-align: center;
}
.sectionContent p{
    font-family: "Circular";
    font-weight: 400;
    width: 330px;
    margin: auto;
    margin-bottom: 2rem;
    color: #100f10;
}
.sectionContent .deliveryButton{
    font-family: 'SnvD';
    font-weight: 400;
    border: none;
    background-color: #FFFF31;
    color: #100f10;
    padding: 0.5rem 1rem;
}
.sectionContent .deliveryButton:hover{
    background-color: #FFFF31;
    color: #100f10;
}
.sectionContent button a{
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #100f10;
}

@media screen and (max-width: 991px) {
    .smartContainer{
        padding-top: 40px;
    }

    .sectionHeader{
        margin-bottom: 50px;
    }
}