#loadingPanel {
  width: 100vw;
  height: 100vh;
  background-color: #100f10;
}

.App {
  text-align: center;
}
#menu{
  font-family: "SnvD";
}

.navbar-dark .navbar-toggler-icon {
  background-image: url(../img/download.png);
}

.navbar-toggler{
  border: none;
  font-size: 0;
  color: transparent !important;
}

.navbar-toggler .navbar-toggler-icon{
  width: 1.1rem;
  height: 1.1rem;
}

/* Header - Slider */
#header .carousel-inner {
  height: 100%;
}

#header .carousel-indicators {
  margin-bottom: 3.5rem;
}

#header .carousel-indicators button[data-bs-target] {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid white;
  opacity: 1;
  border-radius: 50%;
}

#header .carousel-indicators button[data-bs-target].active {
  background-color: white;
}

#faq .accordion-item {
  background-color: transparent;
  border-bottom: 1px solid white;
  color: rgba(255, 255, 255, 0.5);
}

#faq .accordion-flush .accordion-item:first-child {
  border-top: 1px solid white;
}

.loaderContainer{
  width: 100vw;
  height: 100vh;
  background-color: #100f10;
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loaderContainer p{
  color: white;
  /* font-weight: bold; */
  /* font-family: 'Oswald'; */
  letter-spacing: 0.5;
  /* font-size: 3vmax; */
}

.btn,
.form-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: unset !important;
}

#contact .form-control.is-invalid,
#contact .was-validated .form-control:invalid {
  background-image: none !important;
  border-color: #be2007 !important;
}

#contact .form-control.is-valid,
#contact .was-validated .form-control:valid {
  background-image: none !important;
  border-color: #198754 !important;
}

@media screen and (max-width: 991px) {
	.display-5 {
    font-size: 40px;
  }
}
