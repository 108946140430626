.footerContainer{
    background-color: #100f10;
    color: white;
    padding: 5% 0;
}
.footerContainer a{
    font-family: "Space Grotesk";
    font-weight: 400;
    text-decoration: none;
    color: white;
}
.footerContainer a:hover{
    color: #FFFF31;
}

.footerHeader{
    border-bottom: 1px solid #fff;
}
.footerLogo {
    margin-bottom: 0.5rem;
}
.footerLogo img{
    width: 258px;
    margin: 0;
}

.footerContent{
    border-bottom: 1px solid #fff;
    text-align: center;
    padding: 60px 0;
}
.footerContent ul.legal{
    font-family: "Space Grotesk";
    font-weight: 400;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.footerContent ul.social{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 27px;
    margin-bottom: 30px;
}

.footerContent ul.copy{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 26px;
    margin-bottom: 30px;
}
.footerContent ul.copy li p{
    position: relative;
    display: inline;
}
.footerContent ul.copy li p:last-child:before{
    display: none;
}

.copyright{
    position: relative;
    display: inline;
}
.copyright:before{
    content: "";
    background-image: url(../img/copyright.png);
    position: absolute;
    left: -24px;
    top: 2px;
    width: 16px;
    height: 16px;
    display: block;
    background-position: center;
    background-size: contain;
}

/* .footerContent ul.copy li p:after{
    content: ",";
    position: relative;
}
.footerContent ul.copy li:last-child p::after{
    display: none;
} */

.footerContent ul.social a{
    display: block;
    width: 40px;
    height: 40px;
}

.footerContent .info{
    margin: 0;
}
.footerContent p{
    font-family: "Space Grotesk";
    font-weight: 400;
}

@media screen and (max-width: 991px) {
    .footerLogo img{
        margin: auto;
    }
    .footerContainer{
        padding: 50px 24px;
    }

    .footerContent{
        padding: 35px 0;
    }
}

@media screen and (max-width: 767px) {

    .footerContent ul.legal{
        flex-direction: column;
        margin-bottom: 30px;
    }
    .footerContent ul.copy{
        flex-direction: column;
    }

    .footerContent ul.copy{
        margin-top: 30px;
        gap: 16px;
    }

    .footerContent ul.copy li p:after{
        display: none;
    }

    .copyright{
        margin-left: 16px;
    }
}

@media screen and (max-width: 575px) {
	.footerHeader {
        text-align: center;
    }

    .footerContent ul.social svg{
        width: 35px;
    }
}
