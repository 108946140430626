.row1 {
	font-family: "SnvD";
	margin-bottom: 0.1rem;
}

.row2 {
	font-family: "SnvD";
	font-size: 2rem;
	font-weight: 400;
}
