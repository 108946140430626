.whyContainer{
    background-color: #FFFF31;
    padding: 6.3% 0;
}

.sectionHeader{
    font-family: "Apfel Grotezk";
    font-weight: 400;
    margin-bottom: 4.5rem;
    padding-left: 113px;
    color: #100f10;
}

.sectionContent ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 4rem;
    margin: 0;
}

.sectionContent ul li {
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8%;
    transition: all 0.2s ease-in-out;
}
/* .sectionContent ul li:hover {
    transform: scale(1.1);
} */

.icon{
    width: 80px;
    color: #100f10;
}

.text{
    font-family: "Space Grotesk";
    font-weight: 500;
    margin: 1rem 0;
    line-height: 1.3;
    color: #100f10;
}

@media screen and (max-width: 1399px) {
    .sectionHeader{
        padding-left: 90px;;
    }
}

@media screen and (max-width: 1200px) {
    .sectionHeader{
        padding-left: 68px;
    }
    .sectionContent ul li {
        padding: 0 7%;
    }
}

@media screen and (max-width: 991px) {
    .whyContainer{
        padding: 40px 24px;
    }

	.sectionHeader {
        padding-left: 40px;
        margin-bottom: 50px;
    }

    .sectionContent ul li {
        padding: 0 5%;
    }
}

@media screen and (max-width: 767px) {
    .sectionHeader{
        padding-left: 19px;
    }

    .sectionContent ul li {
        padding: 0 3%;
    }
}

@media screen and (max-width: 575px) {
    .sectionHeader {
        padding: 0;
        text-align: center;
    }

	.sectionContent ul li {
        width: 50%;
        padding: 0 15%;
    }
}

