.prices {
	position: relative;
	width: 100%;
	background-color: white;
	padding: 5.4% 0;
}

.sectionHeader {
	font-family: "Apfel Grotezk";
	font-weight: 400;
	margin-bottom: 9.3%;
	text-align: center;
	color: #100f10;
}

.pricesColumn {
	display: flex;
	justify-content: center;
	font-family: "Circular";
}

.pricesColumn:first-child {
	justify-content: flex-start;
}

.pricesColumn:last-child {
	justify-content: flex-end;
}

.pricesCard {
	font-family: 'Circular';
    font-weight: 400;
	width: 100%;
	max-width: 350px;
	transition: all 0.2s ease-in-out;
	padding: 0;
	background-color: rgba(16, 15, 16, 0.8);
}

.pricesCard:hover {
	z-index: 5;
	transform: scale(1.1);
}

.pricesCard:hover h2 {
	color:#FFFF31;
}

.pricesCardList {
	margin: 32px auto 32px;
	display: table;
}

.pricesCard:hover .pricesCardList li:last-child {
	color:#FFFF31;
}

.priceInfoIcon {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 22px;
	cursor: pointer;
}

.pricesCardList li {
	padding-bottom: 8px;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
}
.pricesCardList li i {
	width: 24px;
}
.pricesCardList li span {
	margin-bottom: 0;
}

.pricesCardPrice {
	display: table;
	margin: 0 auto;
}

.pricesCardPrice > div:first-child > span:first-child {
	text-decoration: line-through;
}

.pricesCardPrice > div:first-child > span:last-child {
	background-color: #FFFF31;
	color: #100f10;
	padding: 0 3px;
	margin-left: 5px;
}

@media screen and (max-width: 991px) {
	.prices {
		padding: 40px 24px;
	}

	.sectionHeader {
		margin-bottom: 50px;
	}

	.pricesColumn {
		justify-content: center !important;
	}

	.pricesCard {
		margin-bottom: 25px;
	}

	.pricesCard:hover {
		transform: scale(1.05);
	}

	.sectionHeader h1 {
		font-size: 40px;
	}
}

@media screen and (max-width: 575px) {
	.pricesCardSubtitle {
		font-size: 1rem;
	}

	.pricesCardList li span {
		font-size: 0.85rem;
	}
}
