.header {
    height: auto;
	background-color: #100f10;
	overflow: hidden;
	margin-top: 56px;
}

.headerItem {
	height: auto;
	min-height: 95vh;
	padding: 5.5% 21% 0;
}

.imgContainer{
	overflow: hidden;
    position: relative;
	height: 64vh;
	display: flex;
	padding-right: 0;
    padding-left: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
	flex-direction: column;
    -webkit-box-pack: center;
	justify-content: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.image1 {
	background-image: url('../assets/images/image11.jpg');
}

.image2 {
	background-image: url('../assets/images/image12.jpg');
}

.image3 {
	background-image: url('../assets/images/image13.jpg');
}

.headerCaption {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.headerCaptionTitle {
	font-family: "Apfel Grotezk Fett";
	font-weight: 700;
	max-width: 600px;
	margin-bottom: 50px;
	/* word-spacing: -15px; */
}

.headerCaptionButton,
.headerCaptionButton:active,
.headerCaptionButton:focus,
.headerCaptionButton:focus:active {
	font-family: 'SnvD';
	font-weight: 400;
	text-transform: uppercase;
	background-color: #FFFF31;
	color: #100f10;
	border: none;
	outline: none;
	box-shadow: none;
}
.headerCaptionButton:hover{
  background-color: #FFFF31;
	color: #100f10;
}

.headerCaptionInfo {
	position: static;
    margin-top: 40px;
    padding-bottom: 7%;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5%;
    -webkit-box-orient: vertical;
    flex-direction: row;
    align-items: center;
	justify-content: center;
	gap: 5px;
	font-family: "SnvD";
	font-weight: 400;
	text-transform: uppercase;
	background-color: #100f10;
	word-spacing: -4px;
	color: white;
	text-align: center;
}

.headerCaptionInfoBig {
	position: relative;
	margin-right: 22px;
}

.headerCaptionInfoSmall {
	position: absolute;
	top: 5px;
	right: -23px;
}

@media screen and (max-width: 991px) {
	.headerItem {
		padding: 60px 5% 0;
	}
	
	.headerCaptionInfo {
		bottom: 75px;
	}

	.header {
		margin-top: 52px;
	}

	/* .headerCaptionTitle {
		word-spacing: -12px;
	} */
}

@media screen and (max-width: 767px) {
	.headerCaptionInfoSmall {
		top: 4px;
    	right: -22px;
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 575px) {
	.headerCaptionTitle {
		font-size: 30px !important;
	}

	.headerCaptionInfoSmall {
		top: 3px;
    	right: -21px;
		font-size: 1rem;
	}
}