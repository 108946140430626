.menuNavbar {
	background-color: #100f10;
}
.menuButton{
	line-height: 26px;
}

.menuButton:hover{
	background-color: #FFFF31;
	border-color: #FFFF31;
	color: #100f10;
}

.menuItem{
	font-family: "SnvD";
	font-weight: 400;
}
.menuItem:hover {
	color: #FFFF31 !important;
}

.navbarBrand {
	width: 170px;
	max-width: 170px;
	margin: 0.2rem 0;
	padding-left: 0;
}

.navbarToggle {
	padding-right: 0;
}

@media screen and (max-width: 991px) {
	.menuNavbar {
		padding-right: 24px;
		padding-left: 24px;
	}

	.menuItem {
		text-align: center;
		margin-right: 0 !important;
	}

	.menuButton {
		margin: 10px auto 5px;
		display: table;
	}
}
